<template>
  <div>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Introduction</span>
    </div>
    <div style="margin-top: 10px" />
    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm8
              md8
              lg8
              xl8
              class="font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        <span class="subhead">S</span>howing or presenting data in a graphical or pictorial form is called data visualization.
        Presenting data in a graphical form is useful as it helps us see the information contained in the data, make comparisons, and identify patterns or trends.
        <div style="margin-top: 20px" />
        There are several ways to represent data graphically. In this module, we will discuss three most commonly used ways
        of representing data.
        <br>
        <div style="margin-top: 20px" />
        <h5>&#9673; Pictograph (Pictogram)</h5>
        A pictograph (also known as a pictogram) is a visualization method where the data is represented using pictures or images.
        You will learn more about pictographs in Lesson 3 of this module.
        <div style="margin-top: 10px" />
        <h5>&#9673; Bar Chart (or a Bar Graph) </h5>
        A bar chart (also known as a bar graph) is a visualization method where the data is
        represented using bars of different heights.
        A bar chart is commonly used to represent the grouping of data into separate groups or categories,
        such as days of the week, months of the year, age groups, height groups. You will learn more about bar charts in Lesson 1 of this module.
        <div style="margin-top: 10px" />
        <h5>&#9673; Pie Chart </h5>
        A pie chart is a visualization technique where the data is represented using a circular diagram that resembles a pie.
        Each sector (known as a 'slice') of the circle (known as a 'pie') represents a sample of the data.
        The size of the slice is proportional to the size of the sample relative to whole.
        You will learn more about pie charts in Lesson 2 of this module.
        <div style="margin-top: 20px" />
        <div class="font-weight-medium"
             :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
        >
          <span style="font-family:Oswald"> Outline</span>
        </div>
        This chapter on visualizing data consists of three lessons. Each lesson comes with a visually interactive MagicGraph that offers students a uniquely engaging and effective learning experience.
        <h5> Lesson 1 </h5>
        In this lesson, you will learn how to read and create bar charts.
        <h5> Lesson 2 </h5>
        In this lesson, you will learn how to read and create pie charts.
        <h5> Lesson 3 </h5>
        In this lesson, you will learn how to read and create pictographs.
        <br><br>
      </v-flex>
      <v-flex xs12
              sm4
              md4
              lg4
              xl4
      >
        <h5 style="text-align:center">
          Graphical presentation of the data, such as the pie chart shown below, can help us easily interpret data, make comparisons, and identify patterns.
        </h5>
        <br>
        <v-img class="mx-auto"
               padding="10px"
               max-height="500px"
               max-width="500px"
               src="/assets/PieChart.png"
               contain
        />
      </v-flex>
    </v-layout>
    <div class="font-weight-medium"
         :class="{'display-1': $vuetify.breakpoint.lgAndUp,'headline': $vuetify.breakpoint.mdOnly, 'subheading': $vuetify.breakpoint.smAndDown}"
    >
      <span style="font-family:Oswald"> Let's get started</span>
    </div>
    <div style="margin-top: 10px" />
    <div class="font-weight-light"
         :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
    >
      <span class="subhead">E</span>xplore our comprehensive library of interactive lessons on visualizing data.
    </div>
    <div style="margin-top: 20px" />
    <v-container grid-list-xl text-xs-center>
      <v-layout
        align-center
        justify-center
        row
      >
        <v-flex v-for="topic in topics"
                :key="topic.key"
                xs12
                sm12
                md12
                lg12
                xl12
        >
          <subtopic-card
            :headline="topic.headline"
            :description="topic.description"
            :img="topic.img"
            :path="topic.path"
          />
        </v-flex>
      </v-layout>
    </v-container>
    <br>
    <br>
    <br>
  </div>
</template>
<script>
import SubtopicCard from '../SubTopics.vue'
export default {
    name: 'Vectors',
    components: {
    SubtopicCard
    },
    data () {
      return {
        playerOptions: {
        preload:'auto',
        ratio: '16:9'
        },
      topics: [
      {
        headline: "Bar Charts",
        description: "Learn to read and create bar charts",
        img:"/assets/bar-chart.svg",
        path: "/mathematics/bar"
      },
      { headline: "Pie Charts",
        description: "Learn to read and create pie charts",
        img: "/assets/pie-chart.svg",
        path: "/mathematics/pie"
      },
      { headline: "Pictographs",
        description: "Learn to read and create pictographs",
        img: "/assets/pictogram.svg",
        path: "/mathematics/pic"
      },
//      {
//        headline: "Pythagorean Theorem", description: "Most basic type of electric circuit in which a resistor and a capacitor are connected in series. ",
//          img: "/assets/wdice-4.svg", path: "/mathematics/triangles/pythagorean"
//    }
      ]
    }
  },
    created () {
      this.$store.commit('navigation/resetState');
      this.$store.commit('navigation/changeTitle', 'Visualizing Data');
      this.$store.commit('navigation/changeMenu', 'Visualizing Data');
      let newTopics = [
        {title: 'Bar Charts', img:'/assets/wdice-1.svg', action: () => this.$router.push({path: '/mathematics/bar'})},
        {title: 'Pie Charts', img:'/assets/wdice-2.svg', action: () => this.$router.push({path: '/mathematics/pie'})},
        {title: 'Pictographs',img:'/assets/wdice-3.svg', action: () => this.$router.push({path: '/mathematics/pic'})},
      //  {title: 'Pythagorean Theorem', img:'/assets/wdice-4.svg', action: () => this.$router.push({path: '/mathematics/triangles/pythagorean'})},
      ];
      this.$store.commit('navigation/replaceTopics', newTopics);
      let newshowhome = false;
      this.$store.commit('navigation/toggleshowhome', newshowhome);
      let newMath =true;
      this.$store.commit('navigation/replaceMath', newMath);
      let newLeftArrow =true;
      this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
      let newModule=true;
      this.$store.commit('navigation/replaceModule', newModule);
       },
       metaInfo() {
       return{ title: 'Data Visualization',
               titleTemplate: '%s - Learn interactively',
               meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                       {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                     ]
             }
        }
}
</script>
<style scoped lang="scss">
@import 'src/styles/subtopic-menu.scss';
.subhead {
color: #903;
float: left;
font-family: Georgia;
font-size: 75px;
line-height: 60px;
padding-top: 4px;
padding-right: 8px;}
</style>
